import { Card, Col, Row, Space, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { colorBestTime, colorChartNight, colorClear, colorDeer, colorDeer_alpha, colorHog, colorHog_alpha } from 'res';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryTheme } from 'victory';
import iconSunrise from '../../../assets/image/icons/sunrise.png';
import iconSunset from '../../../assets/image/icons/sunset.png';
import classes from './style.module.scss';

// const CHART_LABELS = ['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const options = {
  lineTension: 0.3,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          stepSize: 0.1,
        },
        type: 'linear',
      },
    ],
  },
};

interface props {
  hours: Array<string>
  data: Array<number>
  label: string
  arraySunImages: Array<string>
  arrayDirection: Array<string>
  sunset: string
  sunrise: string
}

const WeatherVictoryChart: React.FC<props> = (props) => {
  const [maxY, setMaxY] = useState(0);
  const [minY, setMinY] = useState(10000);
  const [dataSets, setDatasets] = useState<any>([]);
  const [tickValues, setTickValues] = useState<number[]>([]);
  const [tickFormat, setTickFormat] = useState<string[]>([]);
  const chartPointSunrise = new Image(25, 25);
  chartPointSunrise.src = iconSunrise;
  const chartPointSunset = new Image(25, 25);
  chartPointSunset.src = iconSunset;

  const points = props.data.map((item, index) => ({ x: index, y: item }));
  const dataChart = {
    labels: props.hours,
    datasets: [
      {
        label: props.label,
        data: points,
        fill: true,
        backgroundColor: colorHog_alpha,
        borderColor: colorHog,
        borderWidth: 2,
      },
      // {
      //   label: '',
      //   data: [
      //     { x: 1.5, y: 1.5 },
      //     { x: 6.5, y: 1.5 },
      //   ],
      //   pointStyle: [chartPointSunrise, chartPointSunset],
      // },
    ],
  };

  const CustomTick = (dumn) => {
    const index = dumn.datum;
    let result = <foreignObject x={dumn.x - 8} y={dumn.y - 8} width={16} height={32}><p className={classes.chart_xLabel}>{dumn.text}</p></foreignObject>;
    if (props.label == 'Miles/Hour') {
      const element = props.arrayDirection[index];
      result = <foreignObject x={dumn.x - 8} y={dumn.y - 8} width={16} height={32}><p className={classes.chart_xLabel}>{dumn.text}</p><p className={classes.chart_xLabel}>{element}</p></foreignObject>;
    } else {
      const element = props.arraySunImages[index];
      const img = <img src={element} alt="" className={classes.chart_sun} />;
      result = <foreignObject x={dumn.x - 8} y={dumn.y - 8} width={16} height={32}><p className={classes.chart_xLabel}>{dumn.text}</p>{img}</foreignObject>;
    }
    return result;
  };

  function getTimePosition(arg: string, start: string) {
    let startHour = 0;
    if (start) {
      startHour = parseInt(start, 10);
      const startDay = start.slice(-2);
      if (startDay == 'PM') {
        startHour += 12;
      }
    }

    const data = arg.split(' ');
    const timeArr = data[0].split(':');
    let value = parseInt(timeArr[0], 10) + parseFloat(timeArr[1]) / 60;
    if (data[1] == 'PM') {
      value += 12;
    }

    value -= startHour;
    if (value < 0) {
      value += 24;
    }
    if (value >= 24) {
      value -= 24;
    }

    value = parseFloat(value.toFixed(2)) / 3;
    return value;
  }

  useEffect(() => {
    const newDatasets: any[] = [];
    let arrayPredict: any[] = [];
    const newTickValues: number[] = [];
    const newTickFormat: string[] = [];
    let max = 0;
    let min = 10000;
    let day_night = 0;
    for (let i = 0; i < props.data.length; i++) {
      newTickValues.push(i);
      newTickFormat.push(props.hours[i]);
      const item = Number(props.data[i]);
      arrayPredict.push({ x: i, y: item });
      if (item > max) {
        max = item;
      }
      if (item < min) {
        min = item;
      }
      const distanceSunrise = getTimePosition(props.sunrise, props.hours[i]);
      if (distanceSunrise < 1) {
        if (i + 1 < props.data.length) {
          const sunValue = item + (props.data[i + 1] - item) * distanceSunrise;
          arrayPredict.push({ x: distanceSunrise + i, y: sunValue });
          newDatasets.push({
            day_night: 0,
            data: arrayPredict,
          });
          arrayPredict = [];
          arrayPredict.push({ x: distanceSunrise + i, y: sunValue });
          day_night = 1;
        }
      } else {
        const distanceSunset = getTimePosition(props.sunset, props.hours[i]);
        if (distanceSunset < 1) {
          if (i + 1 < props.data.length) {
            const sunValue = item + (props.data[i + 1] - item) * distanceSunset;
            arrayPredict.push({ x: distanceSunset + i, y: sunValue });
            newDatasets.push({
              day_night: 1,
              data: arrayPredict,
            });
            arrayPredict = [];
            arrayPredict.push({ x: distanceSunset + i, y: sunValue });
            day_night = 0;
          }
        }
      }
    }
    if (arrayPredict.length > 1) {
      newDatasets.push({
        day_night,
        data: arrayPredict,
      });
      arrayPredict = [];
    }
    setMaxY(max);
    setMinY(min);
    setDatasets(newDatasets);
    setTickFormat(newTickFormat);
    setTickValues(newTickValues);
  }, [props.data]);

  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        padding={{ top: 8, bottom: 32, left: 32, right: 16 }}
        maxDomain={{ y: maxY + (maxY - minY) / 2 }}
        minDomain={{ y: minY - (maxY - minY) < 0 ? 0 : (minY - (maxY - minY)) }}
        height={200}
      >
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="colorDay" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="25%" stopColor={colorBestTime} />
              <stop offset="100%" stopColor="#00000000" />
            </linearGradient>
            <linearGradient id="colorNight" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="25%" stopColor={colorChartNight} />
              <stop offset="100%" stopColor="#00000000" />
            </linearGradient>
          </defs>
        </svg>
        <VictoryLegend
          x={32}
          y={0}
          centerTitle
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: 'black', strokeOpacity: 0.5, strokeDasharray: 2 }, title: { fontSize: 4 }, labels: { fontSize: 8 } }}
          data={[
            { name: 'Day', symbol: { fill: colorBestTime } },
            { name: 'Night', symbol: { fill: colorChartNight } },
            // { name: props.label, symbol: { fill: colorClear } },
          ]}
        />
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={tickFormat}
          style={{ tickLabels: { fontSize: 7 } }}
          tickLabelComponent={<CustomTick />}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 7, angle: -45 },
            axisLabel: { fontSize: 12, opacity: 0.1, fontWeight: 'bold' },
            grid: { stroke: colorClear },
          }}
          label={props.label}
        />
        {dataSets.map((dataset: any, i) => (
          <VictoryArea
            // interpolation="natural"
            key={i}
            style={{
              data: {
                fill: dataset.day_night == 1 ? 'url(#colorDay)' : 'url(#colorNight)',
                fillOpacity: 0.3,
                stroke: dataset.day_night == 1 ? colorBestTime : colorChartNight,
                strokeWidth: 1,
              },
            }}
            data={dataset.data}
          />
        ))}
      </VictoryChart>
    </div>
  );
};

export default WeatherVictoryChart;
