import { Col, DatePicker, Form, Row, Select, Radio, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const FilterActivity = (props) => {
  const [form] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { md } = useBreakpoint();
  const [categoryArr, setCategoryArr] = useState([]);
  const [filterOptions, setFilterOption] = useState({
    count_type: 1,
    camera_id: 0,
    category: 'all',
    tag: '',
    startDate: '',
    endDate: '',
  });

  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);
  const [value, setValue] = useState(1);

  const [alignment, setAlignment] = React.useState('web');

  useEffect(() => {
    if (profile) {
      // get cameras and tags
      Api.CAMERAS_GROUPS_TAGS().then((res: any) => {
        const data = JSON.parse(res.text);
        try {
          setCameraArr(data.cameras);
          setCameraGroups(data.cameraGroups);
          setTagArr(data.tags);
          const newFilter = { ...filterOptions, camera_id: 0 };
          setFilterOption(newFilter);
          props.handleFilters(newFilter);
          form.setFieldsValue(newFilter);
        } catch (e) {
          // console.log(e.message);
        }
      });
      http_load_categories();
    }
  }, [profile]);

  const http_load_categories = () => {
    Api.DETECTION_THRESHOLD().then(((res: any) => {
      const data = JSON.parse(res.text);
      const tcategories = data.filter((item) => item.enable == '1');
      const cates = tcategories.map((item) => (
        { value: item.category.toLowerCase(), name: item.category }
      ));
      cates.unshift({ value: 'all', name: 'All' });
      setCategoryArr(cates);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    const newFilter = { ...filterOptions, startDate: dateString[0], endDate: dateString[1] };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const onChange = (e) => {
    const index = e.target.value;

    let newFilter;
    if (index == 1) {
      newFilter = { ...filterOptions, startDate: '', endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 2) {
      const startDate = moment().add(-1, 'week').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 3) {
      const startDate = moment().add(-2, 'week').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 4) {
      const startDate = moment().add(-1, 'month').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    }

    if (index != 5) {
      props.handleFilters(newFilter);
    }
    setValue(index);
  };

  return (
    <div className={classes.wrapper}>
      <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
        <div className={classes.formInputLabel}>Display Value:</div>
        <Form.Item name="count_type" className={classes.formInputWrapper}>
          <Select size="large">
            <Option key={0} value={0}>Number of Images</Option>
            <Option key={1} value={1}>Minutes of Activity</Option>
          </Select>
        </Form.Item>
        <div className={classes.formInputLabel}>Choose Camera:</div>
        <Form.Item name="camera_id" className={classes.formInputWrapper}>
          <Select size="large">
            <Option key={0} value={0}>All</Option>
            <OptGroup label="Cameras">
              {cameraArr.map((val: any) => (
                <Option key={val.id} value={val.id}>{val.name}</Option>
              ))}
            </OptGroup>
            <OptGroup label="Camera Groups">
              {cameraGroups.map((val: any) => (
                <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <div className={classes.formInputLabel}>Choose Tag:</div>
        <Form.Item name="tag" className={classes.formInputWrapper}>
          <Select size="large">
            <Option key={0} value="">All</Option> :
            {tagArr.map((val: any) => (
              val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
            ))}
          </Select>
        </Form.Item>
        <div className={classes.formInputLabel}>Choose Category:</div>
        <Form.Item name="category" className={classes.formInputWrapper}>
          <Select size="large">
            {categoryArr.map((val: any) => (
              <Option key={val.value} value={val.value}>{val.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <div className={classes.formInputLabel}>Date range:</div>
        <Radio.Group className={classes.radioGroup2} onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={1}>All</Radio>
            <Radio value={2}>Last Week</Radio>
            <Radio value={3}>Last 2 Weeks</Radio>
            <Radio value={4}>Last Month</Radio>
            <Radio value={5}>
              Custom
              {value === 5 ? <RangePicker style={{ width: '100%' }} onChange={onChangeDate} /> : null}
            </Radio>
          </Space>
        </Radio.Group>
      </Form>
    </div>
  );
};

export default FilterActivity;
