import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Popconfirm, Tag } from 'antd';
import classes from './style.module.scss';

const ShareAccountItem = (props) => {
  const clickEdit = () => {
    props.clickEdit(props.account);
  };

  const clickDelete = () => {
    props.clickDelete(props.account);
  };

  const getCameraName = (camera_id: String) => {
    let i;
    for (i = 0; i < props.cameras.length; i++) {
      if (props.cameras[i].id == camera_id) {
        return props.cameras[i].name;
      }
    }
    return '';
  };

  const onClick = () => {
    props.onClick(props.account.camera_ids);
  };

  return (
    <>
      <Card
        type="inner"
        title={props.account.email}
        className={classes.sharedAccountCard}
        hoverable={!props.clickEdit}
        extra={
          props.clickEdit && (
            <span>
              <EditOutlined className={classes.groupItemIcon} onClick={clickEdit} />
              <Popconfirm
                title="Are you sure to remove share account?"
                onConfirm={clickDelete}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className={classes.groupItemIcon} />
              </Popconfirm>
            </span>
          )
        }
      >
        {
          props.account.camera_ids.map((camera_id, i) => (
            <Tag key={i} color="default" style={{ marginBottom: 3 }}>{getCameraName(camera_id)}</Tag>
          ))
        }
      </Card>
    </>
  );
};

export default ShareAccountItem;
