import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Table } from 'antd';

import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { useHistory } from 'react-router-dom';
import { array } from '@amcharts/amcharts4/core';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'IMEI',
    dataIndex: 'IMEI',
    key: 'IMEI',
  },
];

const DashboardCameraTable = () => {
  const history = useHistory();
  const { auth: { profile } } = useAuth();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [location_error, setLocationError] = useState(false);

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const loadData = () => {
    Api.CAMERA_GET_ALL().then(((res: any) => {
      const result = JSON.parse(res.text);
      const data: any = []; let flag_location_error = false;
      result.cameras.forEach((element) => {
        data.push({
          key: element.id, // I added this line
          id: element.id,
          name: element.name,
          IMEI: element.IMEI,
        });
        if (Math.abs(element.latitude) < 2 && Math.abs(element.longitude) < 2) {
          flag_location_error = true;
        }
      });
      setLocationError(flag_location_error);
      setDataSource(data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const clickCamera = () => {
    history.push('/camera');
  };

  return (
    <div>
      {/* <h3>My Cameras</h3> */}
      <Col xs={24}>
        {location_error && (
          <Alert
            message="Action Required."
            description="The location information for your camera seems to be incorrect. Update the location to get the correct weather information based on the camera's location."
            type="warning"
            action={(
              <Button
                size="small"
                type="ghost"
                onClick={clickCamera}
              >
                Go To the Cameras Page.
              </Button>
            )}
            closable
          />
        )}
      </Col>
      <Table dataSource={dataSource} columns={columns} scroll={{ x: 400, y: 500 }} />
    </div>
  );
};

export default DashboardCameraTable;
