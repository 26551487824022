import { Card, Col, Grid, Row, Select, Space, Spin, Switch, Table, Tabs, notification } from 'antd';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import WeatherVictoryChart from 'components/activity/WeatherVictoryChart';
import * as Res from 'res';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;
const CHART_LABELS = ['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const WeatherPage = () => {
  const { auth: { profile } } = useAuth();
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [cameraID, setCameraID] = useState('0');
  const [selectedKey, setSelecedKey] = useState(0);
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [dailyArray, setDailyArray] = useState<any>([]);
  const [dateData, setDateData] = useState<any>();
  const [chartSun, setChartSun] = useState<any>([]);
  const [chartWindDir, setChartWindDir] = useState<any>([]);
  const [sunrise, setSunrise] = useState<string>('');
  const [sunset, setSunset] = useState<string>('');
  const [chartLabel, setChartLabel] = useState<string>('');
  const [chartData, setChartData] = useState<any>([]);
  const [dataHourly, setDataHourly] = useState<any>([]);
  const [chartType, setChartType] = useState<string>('1');
  const [isMillibar, setIsMillibar] = useState<boolean>(true);

  useEffect(() => {
    if (profile) {
      Api.CAMERA_GET_ALL().then(((res: any) => {
        const result = JSON.parse(res.text);
        setCameraArr(result.cameras);
        if (result.cameras.length > 0) {
          setCameraID(result.cameras[0].id);
        }
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  useEffect(() => {
    setChartData(makeChartData());
  }, [dataHourly, chartType, isMillibar]);

  useEffect(() => {
    if (chartType == '1') {
      setChartLabel('Fahrenheit');
    } else if (chartType == '2') {
      setChartLabel('Miles/Hour');
    } else if (chartType == '3') {
      setChartLabel(isMillibar ? 'MB' : 'inHg');
    } else if (chartType == '4') {
      setChartLabel('RH %');
    } else if (chartType == '5') {
      setChartLabel('Chance Of Rain %');
    }
  }, [chartType, isMillibar]);

  useEffect(() => {
    if (cameraID != '0') {
      setLoading(true);
      Api.FORECAST_WEATHER({ camera_id: cameraID }).then(((res: any) => {
        const data = JSON.parse(res.text);
        setLoading(false);
        if (data.success == false) {
          notification.open({
            message: data.error.info,
          });
          setDailyArray([]);
        } else {
          setDailyArray(data);
        }
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [cameraID]);

  useEffect(() => {
    if (dailyArray.length > selectedKey) {
      const data = dailyArray[selectedKey];
      setDateData(data);
      setSunrise(data.sunrise);
      setSunset(data.sunset);
      setDataHourly(data.hourly);
    }
  }, [selectedKey, dailyArray]);

  useEffect(() => {
    const arraySun: string[] = [];
    const arrayWindDir: string[] = [];
    for (let i = 0; i < dataHourly.length; i++) {
      const item = dataHourly[i];
      arrayWindDir.push(item.wind_dir);
      arraySun.push(item.weather_icon);
    }
    setChartWindDir(arrayWindDir);
    setChartSun(arraySun);
  }, [dataHourly]);

  function makeChartData() {
    const arrayData: number[] = [];
    for (let i = 0; i < dataHourly.length; i++) {
      const item = dataHourly[i];
      if (chartType == '1') {
        arrayData.push(item.temperature);
      } else if (chartType == '2') {
        arrayData.push(item.wind_speed);
      } else if (chartType == '3') {
        if (isMillibar) {
          arrayData.push(item.pressure);
        } else {
          arrayData.push(parseFloat((item.pressure / 33.86389).toFixed(2)));
        }
      } else if (chartType == '4') {
        arrayData.push(item.humidity);
      } else if (chartType == '5') {
        arrayData.push(item.chanceofrain);
      }
    }
    return arrayData;
  }

  function dayOfWeekAsString(arg: string) {
    // eslint-disable-next-line no-var
    // eslint-disable-next-line radix
    const argValue = parseInt(arg) - 1;
    return ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][argValue];
  }

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  function getMonthName(arg: string) {
    // eslint-disable-next-line radix
    const argValue = parseInt(arg) - 1;
    if (argValue < 0 || argValue == null) return '';
    return monthNames[argValue];
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: '1',
      render: (_: any, record: any) => (
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
      ),
    },
    {
      title: 'Moon',
      dataIndex: 'moon',
      key: '2',
      render: (_: any, record: any) => (
        <div>
          <img src={`/icons_moon_phase/${record.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.moon_phase}</h5>
        </div>
      ),
    },
    {
      title: 'Sun',
      dataIndex: 'sun',
      key: '3',
      render: (_: any, record: any) => (
        <div>
          <img src={record.weather_icon} alt="" className={classes.img_sun} />
          <br />
          {record.summary}
          <div>
            Rise: {record.sunrise}<br /> Set: {record.sunset}
          </div>
        </div>
      ),
    },
    {
      title: 'Weather',
      dataIndex: 'temp',
      key: '4',
      render: (_: any, record: any) => (
        <div>
          {record.weather_description}
          <br />
          {record.maxtemp}°F / {record.mintemp}°F
        </div>
      ),
    },
    {
      title: 'Humidity',
      dataIndex: 'humidity',
      key: '5',
      render: (_: any, record: any) => (
        <div>
          {record.humidity}%RH
        </div>
      ),
    },
    {
      title: 'Wind Speed/Dir',
      dataIndex: 'wind',
      key: '6',
      render: (_: any, record: any) => (
        <div>
          {record.wind_speed}MPH / {record.wind_dir}
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: '1',
      render: (_: any, record: any) => (
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
      ),
    },
    {
      title: 'Moon',
      dataIndex: 'moon',
      key: '2',
      render: (_: any, record: any) => (
        <div>
          <img src={`/icons_moon_phase/${record.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.moon_phase}</h5>
        </div>
      ),
    },
    {
      title: 'Sun',
      dataIndex: 'sun',
      key: '3',
      render: (_: any, record: any) => (
        <div>
          <img src={record.weather_icon} alt="" className={classes.img_sun} />
          <br />
          {record.summary}
        </div>
      ),
    },
    {
      title: 'Weather',
      dataIndex: 'temp',
      key: '4',
      render: (_: any, record: any) => (
        <div>
          {record.weather_description}
          <br />
          {record.maxtemp}°F / {record.mintemp}°F
        </div>
      ),
    },
  ];

  const onPressureUnitChange = (checked) => {
    setIsMillibar(checked);
  };

  return (
    <>
      <Row>
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Space>
              <div className={classes.formInputLabel}>Camera:&nbsp;</div>
              <Select
                className={classes.select}
                size="large"
                value={cameraID}
                onChange={(value) => {
                  setCameraID(value);
                }}
              >
                <Select.OptGroup label="Cameras">
                  {cameraArr.map((val: any) => (
                    <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>
            </Space>
            <Card bodyStyle={{ padding: '8px' }}>
              {dateData && (
                <div className={classes.divDateSummary}>
                  <div>
                    <img src={dateData.weather_icon} alt="" className={classes.img_sun} />
                  </div>
                  <div className={classes.divTemp}>
                    <b className={classes.Number}>{dateData.temperature}</b> <p>°F</p>
                  </div>
                  <div className={classes.divDetail}>
                    <div>
                      <p className={classes.Number}>Wind: {dateData.wind_dir} {dateData.wind_speed}MPH</p>
                      <p className={classes.Number}>Humidity: {dateData.humidity}%</p>
                    </div>
                  </div>
                  <div className={classes.divDateLocation}>
                    <div>
                      <p className={classes.location}>{dateData.location_name}</p>
                      <p className={classes.date}>({dayOfWeekAsString(dateData.weekday)}) {getMonthName(dateData.month)}/{dateData.day}</p>
                    </div>
                  </div>
                </div>
              )}
              <Tabs defaultActiveKey="1" type="card" onChange={(type) => setChartType(type)}>
                <Tabs.TabPane tab="Temperature" key={1} />
                <Tabs.TabPane tab="Wind" key={2} />
                <Tabs.TabPane tab="Pressure" key={3} />
                <Tabs.TabPane tab="Humidity" key={4} />
                <Tabs.TabPane tab="Chance Of Rain" key={5} />
              </Tabs>
              <div>
                {
                  chartType == '3' &&
                  <Switch checked={isMillibar} checkedChildren="MB" unCheckedChildren="inHg" style={{ position: 'absolute', right: '35px', zIndex: '1', backgroundColor: isMillibar ? Res.colorBestTime_alpha2 : Res.colorOther_alpha2 }} onChange={onPressureUnitChange} />
                }
                <WeatherVictoryChart hours={CHART_LABELS} data={chartData} arraySunImages={chartSun} arrayDirection={chartWindDir} label={chartLabel} sunrise={sunrise} sunset={sunset} />
              </div>
            </Card>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Spin spinning={loading} size="large">
              <Table
                rowSelection={{
                  selectedRowKeys: [selectedKey],
                  type: 'radio',
                  onChange: (selectedRowKeys: any[]) => {
                    setSelecedKey(selectedRowKeys[0]);
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                  },
                }}
                dataSource={dailyArray}
                columns={md ? columns : columnsMobile}
                onRow={(record) => ({
                  onClick: () => {
                    setSelecedKey(record.key);
                  },
                })}
              />
            </Spin>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WeatherPage;
