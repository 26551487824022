import React from 'react';
import { Card, Tag } from 'antd';
import classes from './style.module.scss';

const SharedCamerasItem = (props) => {
  const onClickItem = () => {
    props.onClick(props.account.cameras);
  };

  return (
    <>
      <Card
        type="inner"
        title={props.account.email}
        className={classes.sharedAccountCard}
        onClick={onClickItem}
        hoverable
      >
        {
          props.account.cameras.map((camera, i) => (
            <Tag key={i} color="default" style={{ marginBottom: 3 }}>{camera.name}</Tag>
          ))
        }
      </Card>
    </>
  );
};

export default SharedCamerasItem;
