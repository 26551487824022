import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SendIcon from '@mui/icons-material/Send';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, ThemeProvider } from '@mui/material';
import { Col, Form, Grid, Row, Select, Spin, notification } from 'antd';
import * as Api from 'api';
import { saveAs } from 'file-saver';
import { ImageType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import useDownloader from 'react-use-downloader';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface props {
  activeIndex: number,
  editOptions,
  onLastPage
}

const moonPhaseLabels = ['New Moon', 'Wax. Crescent', '1st Quarter', 'Wax. Gibbous', 'Full Moon', 'Wan. Gibbous', '2nd Quarter', 'Wan. Crescent'];

const SharedGalleryMediaBox: React.FC<props> = ({ activeIndex, editOptions, onLastPage }) => {
  const [trachCounter, setTrashCounter] = useState(0);
  const { images, filterOptions, setImages, tags, setTags } = useApi();
  const { auth: { profile } } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const [loading, setLoading] = useState(false);
  const [imageDetail, setImageDetail] = useState<ImageType>(images[activeIndex]);
  const [filteredTags, setFilteredTags] = useState<any[]>([]);
  const [tagText, setTagText] = useState('');
  const [mediaType, setMediaType] = useState('');
  const { md, xs } = useBreakpoint();
  const [openHD, setOpenHD] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const { download } = useDownloader();

  const editCategories = [
    'Deer (Antlered)',
    'Deer (Non antlered)',
    'Hog',
    'Turkey',
    'People',
    'Vehicle',
    'Predator',
    'Aoudad',
    'Bear',
    'Bison',
    'Elk',
    'Blackbuck',
    'Bird',
    'Whitetail Fawn',
    'Other',
  ];

  useEffect(() => {
    if (images.length <= 0 || currentIndex < 0) {
      return;
    }
    const detail = images[currentIndex];
    setMediaType(detail.thumbnail == '' ? 'image' : 'video');
    setImageDetail(detail);
    setTagText(detail.tag);
  }, [currentIndex, images, activeIndex]);

  useEffect(() => {
    updateFilteredTags('');
  }, [tags]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trachCounter > 0) {
        setTrashCounter(trachCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trachCounter]);

  const onclickLeftArrow = () => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };

  const onclickRightArrow = () => {
    const newIndex = currentIndex + 1;
    if (newIndex >= images.length - 1) {
      onLastPage();
    }
    if (newIndex <= images.length - 1) {
      setCurrentIndex(newIndex);
    }
  };

  function moonPhaseIndex(moon) {
    let temp = 0;
    if (moon < 0.02) { temp = 0; } else if (moon >= 0.02 && moon <= 0.23) { temp = 1; } else if (moon > 0.23 && moon < 0.27) { temp = 2; } else if (moon >= 0.27 && moon <= 0.48) { temp = 3; } else if (moon > 0.48 && moon < 0.52) { temp = 4; } else if (moon >= 0.52 && moon <= 0.73) { temp = 5; } else if (moon > 0.73 && moon < 0.77) { temp = 6; } else if (moon >= 0.77 && moon <= 0.98) { temp = 7; }
    return temp;
  }

  function modifyCategory(detail) {
    if (detail.category == 'Deer') {
      return `Deer (${detail.category_sub == 'Antlered' ? 'Antlered' : 'Non Antlered'})`;
    }
    return detail.category;
  }

  const updateFilteredTags = (value) => {
    const res: any = [];
    if (!value || value == '') {
      res.push('-CLEAR-');
      tags.forEach((element) => {
        if (element.tag !== '') { res.push(element.tag); }
      });
    } else {
      let newTag = true;
      tags.forEach((element) => {
        if (element.tag.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
          res.push(element.tag);
        }
        if (element.tag.toUpperCase() == value.toUpperCase()) {
          newTag = false;
        }
      });
      if (newTag) {
        res.push(`-NEW- ${value}`);
      }
    }
    setFilteredTags(res);
  };

  const onChangeCategory = (e) => {
    httpEdit('category', e, () => {
      let shouldMove = false;
      if (filterOptions.category == 'All' || filterOptions.category == undefined) {
        shouldMove = false;
      } else if ((filterOptions.category == 'Deer' && filterOptions.antlered == 0)) {
        if (e.includes('Deer')) {
          shouldMove = false;
        } else {
          shouldMove = true;
        }
      } else {
        shouldMove = true;
      }

      const newImages = [...images];
      if (shouldMove) {
        newImages.splice(currentIndex, 1);
        if (currentIndex == images.length - 1) {
          setCurrentIndex(currentIndex - 1);
        }
      } else if (e.includes('Deer')) {
        const arr = e.replace(/[)(]/g, '').split(' ');
        newImages[currentIndex] = { ...newImages[currentIndex], category: arr[0], category_sub: arr[1] ? arr[1] : 'No' };
      } else {
        newImages[currentIndex].category = e;
      }
      setImages(newImages);
    });
  };

  const onChangeTag = (tag) => {
    setTagText(tag);
    if (imageDetail.tag != tag) {
      httpEdit('tag', tag, () => {
        let shouldMove = true;
        if (filterOptions.tag == '0' || filterOptions.tag == undefined) {
          shouldMove = false;
        }
        const newImages = [...images];
        if (shouldMove) {
          newImages.splice(currentIndex, 1);
          if (currentIndex == images.length - 1) {
            setCurrentIndex(currentIndex - 1);
          }
        } else {
          newImages[currentIndex].tag = tag;
        }
        setImages(newImages);
        notification.open({
          message: 'Your Change was Successful',
        });
      });
    }
  };

  const onTrash = () => {
    setTrashCounter(3);
  };

  const onDownload = (imageUrl, imageName) => {
    saveAs(imageUrl, imageName);
  };

  const onSendHD = () => {
    setOpenHD(false);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
      camera_id: imageDetail.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const onSendVideo = () => {
    setOpenVideo(false);
    setTrashCounter(0);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
      camera_id: imageDetail.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const trashConfirm = () => {
    setTrashCounter(0);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
    };
    setLoading(true);
    Api.IMAGE_TRASH(params).then((() => {
      setLoading(false);
      const newImages = [...images];
      newImages.splice(currentIndex, 1);
      if (currentIndex == images.length - 1) {
        setCurrentIndex(currentIndex - 1);
      }
      setImages(newImages);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const httpEdit = (field, value, callback) => {
    const params = {
      image_id: imageDetail.id,
      field,
      value,
    };

    setLoading(true);
    Api.IMAGE_EDIT_ONE(params).then(((res: any) => {
      callback(res);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const http_load_tags = () => {
    Api.IMAGE_ALL_TAGS({ user_id: profile.user_id }).then(((res: any) => {
      const tagArr = JSON.parse(res.text);
      setTags(tagArr);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <div className={classes.mediaboxWrapper}>
        <div style={{ position: 'relative', width: '100%' }}>
          {mediaType == 'video' && (
            <>
              <div className={classes.imageZoom}>
                <IconButton onClick={() => download(`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`, imageDetail.image_name)}>
                  <FileDownloadIcon />
                </IconButton>
              </div>
              <ReactPlayer url={`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`} controls width="100%" height="auto" style={{ minWidth: '480px', minHeight: '320px' }} />
            </>
          )}
          {mediaType !== 'video' && (
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className={classes.imageZoom}>
                    <IconButton onClick={() => zoomIn(1, 300)}>
                      <ZoomInIcon />
                    </IconButton>
                    <IconButton onClick={() => zoomOut(1, 300)}>
                      <ZoomOutIcon />
                    </IconButton>
                    <IconButton onClick={() => resetTransform()}>
                      <ZoomOutMapIcon />
                    </IconButton>
                    <IconButton onClick={() => download(`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`, imageDetail.image_name)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </div>
                  <TransformComponent contentClass={classes.react_transform_component} wrapperClass={classes.react_transform_wrapper}>
                    <img className={classes.image} src={`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`} alt="" />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          )}
          <div className={classes.actionBarLeft}>
            <span className={classes.leftArrow} onClick={onclickLeftArrow}>❮</span>
          </div>
          <div className={classes.actionBarRight}>
            <span className={classes.rightArrow} onClick={onclickRightArrow}>❯</span>
          </div>
          <div className={classes.divStar}>
            <ThemeProvider theme={outerTheme}>
              {imageDetail.is_hd != '1' && imageDetail.thumbnail == '' && imageDetail.is_thumbnail != '1' && imageDetail.is_thumbnail != '3' && (
                <div style={{ marginRight: '8px', display: 'inline-block' }}>
                  <Fab size="medium" variant="extended" onClick={() => { setOpenHD(true); }} style={{ display: editOptions.able_request_hd ? 'flex' : 'none' }}>
                    <SendIcon sx={{ mr: 1 }} />
                    Request XL Image
                  </Fab>
                  <Dialog
                    open={openHD}
                    onClose={() => { setOpenHD(false); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Do you wish to continue?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures.
                        Please monitor your data usage page and contact support with any questions.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => { setOpenHD(false); }}>Disagree</Button>
                      <Button onClick={onSendHD} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              {imageDetail.is_thumbnail == '1' && (
                <div style={{ marginRight: '8px', display: 'inline-block' }}>
                  <Fab size="medium" variant="extended" onClick={() => { setOpenVideo(true); }} style={{ display: editOptions.able_request_hd ? '' : 'none' }}>
                    <SendIcon sx={{ mr: 1 }} />
                    Request Video
                  </Fab>
                  <Dialog
                    open={openVideo}
                    onClose={() => { setOpenVideo(false); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Do you wish to continue?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures.
                        Please monitor your data usage page and contact support with any questions.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => { setOpenVideo(false); }}>Disagree</Button>
                      <Button onClick={onSendVideo} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              <Fab size="medium" aria-label="like" style={{ marginRight: '8px', display: editOptions.able_delete ? '' : 'none' }} onClick={trashConfirm}>
                <DeleteIcon />
              </Fab>
            </ThemeProvider>
          </div>
        </div>
        {imageDetail && (
          <div className={classes.cameraInfoText} style={{ height: md ? 60 : 120 }}>
            <Row style={{ height: '100%' }}>
              <Col md={8} xs={24}>
                <div className={classes.infoCol}>
                  <span>
                    {imageDetail.weather_description}
                  </span>
                  <span>
                    {
                      imageDetail.weather_icon == '' ? (<img src={`/icons_weather/${imageDetail.icon}.png`} alt="" />) : (<img src={imageDetail.weather_icon} alt="" />)
                    }
                  </span>
                  <span>{Math.round(imageDetail.temp)}F {imageDetail.rh}%RH {imageDetail.wind_direction} {imageDetail.wind_speed}MPH</span>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <div className={classes.infoCol}>
                  <span>Sun Rise: {imageDetail.sunrise} Set: {imageDetail.sunset}</span>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <div className={classes.infoCol}>
                  <span>
                    {imageDetail.weather_icon == '' ? <img src={`/icons_moon/moon${moonPhaseIndex(imageDetail.moon)}.png`} alt="" /> : <img src={`/icons_moon_phase/${imageDetail.moon_phase}.png`} alt="" />}
                    &nbsp;
                    {imageDetail.weather_icon == '' ? moonPhaseLabels[moonPhaseIndex(imageDetail.moon)] : imageDetail.moon_phase}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Row className={classes.editDiv}>
          <Col span={12} style={{ display: editOptions.able_category_change ? 'block' : 'none' }}>
            <Select placeholder="Change Category To:" onChange={onChangeCategory} value={modifyCategory(imageDetail)} dropdownStyle={{ position: 'fixed' }} className={classes.editField}>
              {editCategories.map((val, i) => (
                <Option key={i} value={val}>{val}</Option>
              ))}
            </Select>
          </Col>
          <Col span={12} style={{ display: editOptions.able_tag ? 'block' : 'none' }}>
            <div className={classes.editField}>
              <Form.Item name="to_tag" style={{ marginBottom: 0 }}>
                <Select placeholder="Change Tag To:" onChange={onChangeTag} dropdownStyle={{ position: 'fixed' }} disabled={!editOptions.able_tag}>
                  <Option key="tag0" value="">Clear</Option>
                  {tags.map((val, i) => (
                    <Option key={`tag${i + 1}`} value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default SharedGalleryMediaBox;
