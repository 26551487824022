import { AreaChartOutlined, CameraOutlined, CloudOutlined, DashboardOutlined, DeleteOutlined, FieldTimeOutlined, FileImageOutlined, GroupOutlined, HomeOutlined, NodeIndexOutlined, SmallDashOutlined, TagsOutlined, HeartOutlined, UserOutlined, SlidersOutlined, ShareAltOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import React from 'react';
import { PATHS } from 'res/routes';

export default {
  route: {
    path: '/',
    routes: [
      {
        path: PATHS.SHARED_CAMERA,
        name: 'Shared Cameras',
        icon: <DeploymentUnitOutlined />,
      },
      {
        path: PATHS.SHARE_IMAGE,
        name: 'Shared Images',
        icon: <FileImageOutlined />,
      },
    ],
  },
  location: {
    pathname: '/',
  },
};
