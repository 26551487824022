import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import DashboardImageCard from 'components/activity/dashboardImageCard';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { Carousel, Spin } from 'antd';

am4core.useTheme(am4themes_animated);

const DashboardRecently = () => {
  const { auth: { profile } } = useAuth();
  const [images, setImages] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      // get cameras and tags
      setLoading(true);
      Api.DASHBOARD_RECENTLY_IMAGES().then((res: any) => {
        const result = JSON.parse(res.text);
        setImages(result.images);
        setLoading(false);
      });
    }
  }, [profile]);

  return (
    <div>
      {/* <h3>Recently uploaded images</h3> */}
      <Spin spinning={loading} size="large">
        <Carousel
          autoplay
          dotPosition="top"
        >
          {images.map((e, i) => (
            <DashboardImageCard key={i} detail={e} />
          ))}
        </Carousel>
      </Spin>
    </div>

  );
};

export default DashboardRecently;
