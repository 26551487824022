import { Card, Col, Row, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import * as Api from 'api';
import SharedCamerasItem from 'components/share/SharedCamerasItem';
import ShareCameraMap from 'components/share/shareCameraMap';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const SharedCameraPage = () => {
  const { auth: { profile } } = useAuth();
  const [selectedCameras, setSelectedCameras] = useState<any[]>([]);
  const [sharedAccounts, setSharedAccounts] = useState<any[]>([]);
  const leftMap = useRef<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      getSharedAccounts();
    }
  }, [profile]);

  const getSharedAccounts = () => {
    setLoading(true);
    Api.SHRAED_CAMERA().then(((res: any) => {
      const accounts = JSON.parse(res.text);
      setSharedAccounts(accounts);
      if (accounts.length > 0) {
        setSelectedCameras(accounts[0].cameras);
      }
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onclickInnerCard = (cameras) => {
    setSelectedCameras(cameras);
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <Row>
          <Col md={12} xs={24} className={classes.leftMap}>
            <ShareCameraMap cameras={selectedCameras} ref={leftMap} />
          </Col>
          <Col md={12} xs={24} className={classes.sharedAccount}>
            <Card title="Shared Camera by Others">
              {
                sharedAccounts.map((account, i) => (
                  <SharedCamerasItem key={i} account={account} onClick={onclickInnerCard} />
                ))
              }
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default SharedCameraPage;
